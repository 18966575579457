import React from 'react';

import { Row, Col } from '/src/components/atoms/GridRow';
import Layout from '/src/components/layout';
import SEO from '/src/components/atoms/Seo';
import Button from '/src/components/atoms/Button';
import ContentSection from '/src/components/atoms/ContentSection';

const MimPage = () => (
  <Layout>
    <SEO title="Magic Is Might Album" />
    <ContentSection
      splash
      parallax
      center
      bgSrc="/images/uploads/eoa_storm.jpg"
    >
      <h1 className="heading1">Magic Is Might</h1>
      <Row center>
        <Col width={6}>
          <p className="heading3">A Wizard Rock album</p>
          <p className="space-top">
            An 18-minute epic narration of Albus Dumbledore &amp; Gellert
            Grindelwald
          </p>
          <Button
            cta
            component="a"
            href="//music.apple.com/us/album/magic-is-might/1448121125"
            target="_blank"
            rel="noopener nofollow"
          >
            Buy it on Apple Music
          </Button>
          <Button
            cta
            component="a"
            href="//open.spotify.com/album/6PJIcVwI25EHAGntbLS7yb"
            target="_blank"
            rel="noopener nofollow"
          >
            Listen on Spotify
          </Button>
        </Col>
      </Row>
    </ContentSection>
  </Layout>
);

export default MimPage;
